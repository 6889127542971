

.privacy-policy-container {
    font-family: 'MagistralBIack';
    src: url('/src/assets/Magistral-Book.woff2') format('woff2'),
         url('/src/assets/Magistral-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    line-height: 1.6;
    color: #fff;
    padding: 20px;
}
.privacy-policy-content {
    background-color: white; /* Set background to white */
    color: #333; /* Set text color for readability */
    padding: 20px;
  }
.header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-logo {
    max-width: 100px; /* Adjust size as needed */
    margin-bottom: 20px; /* Space between logo and title */
  }
  
  .header-title {
    font-family: 'MagistralBIack';

    color: white;
    font-weight: bold;
    font-size: 3em; /* Adjust size as needed */
  }
.video-header {
    position: relative;
    height: 300px; /* Adjust as needed */
    cursor: pointer;
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
    z-index: -1;
  }
  

  