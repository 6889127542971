@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #111820;
}


@font-face {
    font-family: 'MagistralBIack';
    src: url('/src/assets/Magistral-Book.woff2') format('woff2'),
         url('/src/assets/Magistral-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  

  