/* CybersecurityTimeline.css */

/* Header and Video */
.video-header {
    position: relative;
    height: 300px;
    cursor: pointer;
    z-index: 2;
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  
  .header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-logo {
    max-width: 100px;
    margin-bottom: 20px;
  }
  
  .header-title {
    font-family: 'MagistralBIack';
    color: white;
    font-weight: bold;
    font-size: 3em;
  }
  
  /* Timeline Container */
  .timeline-container {
    padding: 20px;
    background-color: #f5f5f5;
    color: #333;
  }
  
  .timeline-title {
    text-align: center;
    margin-bottom: 20px;
    color: #97d700;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .timeline-event {
    margin: 10px 0;
    padding: 10px 20px;
    background: #fff;
    border-left: 4px solid #97d700;
    border-radius: 4px;
    width: 80%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .timeline-event h2 {
    margin: 0 0 10px;
    color: #97d700;
  }
  
  .timeline-event ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .timeline-event ul ul {
    list-style-type: circle;
  }
  
  .timeline-event p {
    margin: 0;
  }
  