

.isp-container {
    font-family: 'MagistralBIack';
    src: url('/src/assets/Magistral-Book.woff2') format('woff2'),
         url('/src/assets/Magistral-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    line-height: 1.6;
    background-color: #f4f4f4;
    color: #333;
    padding: 20px;
    margin: 0 auto; /* Centering if necessary */

}
.isp-content {
    background-color: white; /* Set background to white */
    color: #333; /* Set text color for readability */
    padding: 20px;
}
.header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-logo {
    max-width: 100px; /* Adjust size as needed */
    margin-bottom: 20px; /* Space between logo and title */
  }
  
  .header-title {
    font-family: 'MagistralBIack';

    color: white;
    font-weight: bold;
    font-size: 3em; /* Adjust size as needed */
  }
.video-header {
    position: relative;
    height: 300px; /* Adjust as needed */
    cursor: pointer;
    z-index: 2;
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
    z-index: -1;
  }
  
  .isp-name {
    font-size: 1.2rem; /* Adjust size as necessary */
    color: #97d700; /* Brand color for text */
  }
  
  .isp-name:hover {
    background-color: #202830; /* Darker shade for hover background */
  }
    /* ISP.css */
@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.tag-cloud-item {
    animation: float 3s ease-in-out infinite;
}
