@keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
  .blinking-cursor {
    font-weight: 100;
    color: #fff; /* Adjust the color to match your text */
    animation: blink 1s step-end infinite;
  }
  